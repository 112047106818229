import './App.css';
import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';


const handleGenerate = async (setOverview, setSkills, setSubjects, setLoading) => {
  setLoading(1);
  const url = 'https://api.openai.com/v1/chat/completions';
  const method = 'POST';
  const headers = {
    'Authorization': process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json'
  };
  let body = JSON.stringify(
    {
      "model": "gpt-3.5-turbo",
      "messages": [
        {
          "role": "system",
          "content": process.env.REACT_APP_PROMPT1
        },
        {
          "role": "user",
          "content": document.getElementById("description").value
        }
      ]
    }
  );

  const resOverviewlData = await fetch(url, {method, headers, body});
  const resOverview = await resOverviewlData.json();

  console.log(JSON.stringify(resOverview));
  setOverview(resOverview.choices[0].message.content);

  body = JSON.stringify(
    {
      "model": "gpt-3.5-turbo",
      "messages": [
        {
          "role": "system",
          "content": process.env.REACT_APP_PROMPT2
        },
        {
          "role": "user",
          "content": document.getElementById("description").value
        }
      ]
    }
  );

  const resSkillData = await fetch(url, {method, headers, body});
  const resSkills = await resSkillData.json();

  console.log(JSON.stringify(resSkills));
  setSkills(resSkills.choices[0].message.content);

  setLoading(2);

  body = JSON.stringify(
    {
      "model": "gpt-3.5-turbo",
      "messages": [
        {
          "role": "system",
          "content": process.env.REACT_APP_PROMPT3
        },
        {
          "role": "user",
          "content": document.getElementById("description").value
        }
      ]
    }
  );

  const resSubjectData = await fetch(url, {method, headers, body});
  const resSubjects = await resSubjectData.json();

  console.log(JSON.stringify(resSubjects));
  setSubjects(resSubjects.choices[0].message.content);  

  setLoading(0);

}


function App() {
  const [overview, setOverview] = useState("");
  const [skills, setSkills] = useState("");
  const [subjects, setSubjects] = useState("");
  const [loading, setLoading] = useState(0);

  return (
    <div className="App">
      <AppBar position="static" color="primary">
        <Container maxWidth="md" align="center">
          <Typography variant="h3" component="div" sx={{ flexGrow: 1, pt: 15, pb: 2}}>
            Job Description Generator
          </Typography>
          <Typography variant="body1" component="div" sx={{ flexGrow: 1, pb: 6}}>
            業務概要を入力すると、その業務に必要なスキルと評価項目をChatGPTで分析します。<br />
            ジョブディスクリプションの作成や業務目標・学習目標の設定に。
          </Typography>

          <Paper
            component="form"
            fullWidth
          >
            <FormControl fullWidth>
              <TextField
                id="description"
                placeholder="業務概要を入力してください"
                multiline
              />
            </FormControl>
          </Paper>
          <Box align="center" mt={3} mb={15}>
            {loading === 0 ? (
              <>
                <Button variant="outlined" color="inherit" size="large" onClick={() => handleGenerate(setOverview, setSkills, setSubjects, setLoading)}>分析する</Button>
              </>
            ) : (
              <>
                <Button variant="outlined" color="inherit" size="large"><CircularProgress color="inherit" my={2} sx={{marginRight: 2}}/>{loading === 1 ? ("スキルを分析しています…") : ("評価項目を分析しています…")}</Button>
              </>
            )}
          </Box>
        </Container>
      </AppBar>
      <main>
        <Box
          sx={{
            bgcolor: '#f5f5f5',
            pt: 6,
            pb: 15,
          }}
        >
          <Container maxWidth="md">
            <Typography variant="h5" component="h5" mt={5}><b>スキル概要</b></Typography>
            <Typography variant="body2">その業務を遂行するために必要なスキルを5種類提案します。十分に習得出来ているスキル、不足しているスキルを確認しましょう。</Typography>
            <Card sx={{ my: 3 }}>
              <CardContent>
                <Typography variant="body1" sx={{whiteSpace: 'pre-line'}}>
                  {overview}
                </Typography>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>

            <Typography variant="h5" component="h5" mt={10}><b>スキル詳細</b></Typography>
            <Typography variant="body2">具体的なスキル項目を10個提案します。このスキル項目は、Udemyのカテゴリ分類にのっとっています。不足しているスキルを学習して、業務の実行力を高めましょう。</Typography>
            <Card sx={{ my: 3 }}>
              <CardContent>
                <Typography variant="body1" sx={{whiteSpace: 'pre-line'}}>
                  {skills}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" sx={{marginLeft: 'auto'}} href="https://www.udemy.com/ja/sitemap/" target="_blank" rel="noopener noreferrer"><LaunchIcon fontSize="small" sx={{marginRight: 1}}/>Udemyで学ぶ</Button>
              </CardActions>
            </Card>

            <Typography variant="h5" component="h5" mt={10}><b>評価項目</b></Typography>
            <Typography variant="body2">その業務の担当者が目指すKPIを5個提案します。日常的にこの数値の達成度を測定・確認し、業務目標や業務評価の指標として活用しましょう。</Typography>
            <Card sx={{ my: 3 }}>
              <CardContent>
                <Typography variant="body1" sx={{whiteSpace: 'pre-line'}}>
                  {subjects}
                </Typography>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>

          </Container>
        </Box>
      </main>
      <Box sx={{ bgcolor: 'white', py: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Job Description Generator
        </Typography>
        
        <Typography
          variant="subtitle2"
          align="center"
          color="text.secondary"
          component="p"
        >
          <Link href="https://studymeter.jp" color="inherit" target="_blank" rel="noopener noreferrer" underline="hover">運営会社</Link>　|　
          <Link href="https://studymeter.jp/termsofuse" color="inherit" target="_blank" rel="noopener noreferrer" underline="hover">ご利用規約</Link>　|　
          <Link href="https://studymeter.jp/privacy" color="inherit" target="_blank" rel="noopener noreferrer" underline="hover">プライバシーポリシー</Link>
        </Typography>

        <Typography
          variant="subtitle2"
          align="center"
          color="text.secondary"
          component="p"
          mt={4}
        >
          Studymeter Inc. All Rights Reserved.
        </Typography>
      </Box>

    </div>
  );
}

export default App;
